import { HeaderCard } from 'designsystem/components/3-organisms/HeaderCard';
import { VideoFeature } from '../Video';

export const HeaderCardFeature: React.FC<Content.HeaderCard> = ({ content, settings, ...props }) => {
	const { image: oldImage, picture, heading, teaser, text, video: videoArray } = content?.properties ?? {};
	const video = videoArray?.[0];
	const { backgroundStyle, mediaAspectRatio } = settings?.properties ?? {};

	const imageObj = picture?.length > 0 ? picture[0]?.content?.properties.image : oldImage;
	const { url, focalPoint } = imageObj ?? {};
	const altText = picture?.length > 0 ? picture[0]?.content?.properties.altText : imageObj?.properties?.altText;
	const altTextChecked = altText == '""' ? '' : altText;

	return (
		<HeaderCard
			image={
				url && {
					altText: altTextChecked,
					url,
					focalPoint,
				}
			}
			video={
				!imageObj && video ? (
					<VideoFeature content={video.content} documentType={video.documentType} noBackground={true} settings={video.settings} />
				) : null
			}
			heading={heading}
			teaser={teaser}
			text={text}
			style={backgroundStyle ? backgroundStyle : 'White'}
			aspectRatio={mediaAspectRatio}
			{...settings?.properties}
			{...props}
		/>
	);
};
